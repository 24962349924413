// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-room-js": () => import("F:\\Shared\\www\\riadchebbi\\site\\src\\templates\\room.js" /* webpackChunkName: "component---src-templates-room-js" */),
  "component---src-templates-suite-js": () => import("F:\\Shared\\www\\riadchebbi\\site\\src\\templates\\suite.js" /* webpackChunkName: "component---src-templates-suite-js" */),
  "component---src-templates-blog-js": () => import("F:\\Shared\\www\\riadchebbi\\site\\src\\templates\\blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-activity-js": () => import("F:\\Shared\\www\\riadchebbi\\site\\src\\templates\\activity.js" /* webpackChunkName: "component---src-templates-activity-js" */),
  "component---src-pages-404-js": () => import("F:\\Shared\\www\\riadchebbi\\site\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("F:\\Shared\\www\\riadchebbi\\site\\src\\pages\\about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-activities-js": () => import("F:\\Shared\\www\\riadchebbi\\site\\src\\pages\\activities.js" /* webpackChunkName: "component---src-pages-activities-js" */),
  "component---src-pages-blog-js": () => import("F:\\Shared\\www\\riadchebbi\\site\\src\\pages\\blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("F:\\Shared\\www\\riadchebbi\\site\\src\\pages\\contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-desert-camp-js": () => import("F:\\Shared\\www\\riadchebbi\\site\\src\\pages\\desert-camp.js" /* webpackChunkName: "component---src-pages-desert-camp-js" */),
  "component---src-pages-food-drinks-js": () => import("F:\\Shared\\www\\riadchebbi\\site\\src\\pages\\food-drinks.js" /* webpackChunkName: "component---src-pages-food-drinks-js" */),
  "component---src-pages-index-js": () => import("F:\\Shared\\www\\riadchebbi\\site\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-luxury-rooms-merzouga-js": () => import("F:\\Shared\\www\\riadchebbi\\site\\src\\pages\\luxury-rooms-merzouga.js" /* webpackChunkName: "component---src-pages-luxury-rooms-merzouga-js" */),
  "component---src-pages-luxury-suites-merzouga-js": () => import("F:\\Shared\\www\\riadchebbi\\site\\src\\pages\\luxury-suites-merzouga.js" /* webpackChunkName: "component---src-pages-luxury-suites-merzouga-js" */),
  "component---src-pages-privacy-policy-js": () => import("F:\\Shared\\www\\riadchebbi\\site\\src\\pages\\privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("F:\\Shared\\www\\riadchebbi\\site\\src\\pages\\terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */)
}

